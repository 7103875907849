
import { defineComponent } from "vue";
import { DeleteOutlined } from "@ant-design/icons-vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import usePayoutDetail from "@/hooks/checking/spotChecking/pendingApproval/usePayoutDetail";
import useStripedRowClass from "@/utils/payment/useStripedRowClass";
import { DataProps } from "./type";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const { query, data, column, search, reset } = usePayoutDetail();
    const init = () => {
      search();
    };
    // 斑马纹
    const getRowClass = useStripedRowClass<DataProps>();
    init();
    return {
      query,
      data,
      column,
      getRowClass,
      reset,
      search,
    };
  },
  components: {
    TableWrapper
  },
});
